var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('div',{staticClass:"limited-accounts"},[_c('h4',{staticClass:"limited-accounts__heading infoText"},[_vm._v(" Did you know that you can create limited accounts for your main account? ")]),_c('p',{staticClass:"limited-accounts__text"},[_vm._v(" With a limited account, you can allow others to access specific sections of your account, such as tickets, news and product updates, and guides, without giving them access to critical information. ")]),_c('p',{staticClass:"limited-accounts__text"},[_vm._v(" Limited accounts have their own custom simple home page, where they can easily see the information they need without being overwhelmed. This feature is perfect for sharing your account with team members or partners who need access to certain information without being able to modify important data. ")]),_c('p',{staticClass:"limited-accounts__text"},[_vm._v(" Try creating a limited account today and see how it can improve your workflow! ")])])]),_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"cols":"9"}},[(!_vm.edit)?_c('form-wizard',{staticClass:"wizard-vertical mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"layout":"vertical","finish-button-text":"Submit","back-button-text":"Previous","start-index":0},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{ref:"AccessTab",attrs:{"title":"Create Account","icon":"feather icon-user"}},[_c('validation-observer',{ref:"rulesAccesTab"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Access ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Enter The Access Details. ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email *","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","placeholder":"john.doe@email.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3695246347)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Password *","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"password","placeholder":"Minimum of 6 characters","vid":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2368637609)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Confirm Password *","label-for":"confirmpassword"}},[_c('validation-provider',{attrs:{"name":"confirmpassword","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirmpassword","type":"password","placeholder":"Re-type Password"},model:{value:(_vm.confirmpassword),callback:function ($$v) {_vm.confirmpassword=$$v},expression:"confirmpassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2255203877)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Additional Permissions :"}},[_c('b-form-checkbox-group',{model:{value:(_vm.selectedPermissions),callback:function ($$v) {_vm.selectedPermissions=$$v},expression:"selectedPermissions"}},_vm._l((_vm.additionalPermissions),function(permission,index){return _c('b-form-checkbox',{key:index,attrs:{"value":permission}},[_vm._v(" "+_vm._s(permission)+" ")])}),1)],1)],1)],1)],1)],1)],1):_vm._e()],1),_c('b-col',{staticClass:"mx-auto",attrs:{"cols":"9"}},[_c('b-modal',{attrs:{"id":"edit-modal","ok-title":"Edit","size":"lg","centered":"","no-close-on-backdrop":"","title":"Edit Sub Account","container-class":"modal-class"},on:{"ok":_vm.formEditted,"hidden":_vm.handleClose,"cancel":_vm.handleClose}},[(_vm.edit)?_c('form-wizard',{staticClass:"wizard-vertical",attrs:{"color":"#7367F0","title":null,"subtitle":null,"layout":"vertical","finish-button-text":"Edit","back-button-text":"Previous","start-index":0},on:{"on-complete":_vm.formEditted}},[_c('tab-content',{ref:"AccessTabEdit",attrs:{"title":"Edit Account","icon":"feather icon-edit"}},[_c('validation-observer',{ref:"rulesAccesTab"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Access ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Edit The Access Details. ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email *","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","placeholder":"john.doe@email.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3695246347)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Password *","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"password","placeholder":"Minimum of 6 characters","vid":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',[_vm._v("Leave it blank if you don't wish to change it")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2448920502)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Confirm Password *","label-for":"confirmpassword"}},[_c('validation-provider',{attrs:{"name":"confirmpassword","rules":"min:6|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirmpassword","type":"password","placeholder":"Re-type Password"},model:{value:(_vm.confirmpassword),callback:function ($$v) {_vm.confirmpassword=$$v},expression:"confirmpassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2255203877)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Additional Permissions :"}},[_c('b-form-checkbox-group',{model:{value:(_vm.selectedPermissions),callback:function ($$v) {_vm.selectedPermissions=$$v},expression:"selectedPermissions"}},_vm._l((_vm.additionalPermissions),function(permission,index){return _c('b-form-checkbox',{key:index,attrs:{"value":permission}},[_vm._v(" "+_vm._s(permission)+" ")])}),1)],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)],1),_c('div',{staticStyle:{"margin-top":"50px","margin-bottom":"100px"}},[(_vm.tableData.length > 0)?_c('h3',{staticStyle:{"margin-bottom":"25px"}},[_vm._v(" Your sub-accounts ")]):_vm._e(),(_vm.tableData.length > 0)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.tableData},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field ==='actions')?_c('div',{staticStyle:{"display":"flex","gap":"10px"}},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.edit-modal",modifiers:{"edit-modal":true}}],staticClass:"btn btn-warning btn-sm",on:{"click":function($event){return _vm.editAccount(props.row.id)}}},[_vm._v(" Edit ")]),_c('button',{staticClass:"btn btn-danger btn-sm",on:{"click":function($event){return _vm.deleteAccount(props.row.id)}}},[_vm._v(" Delete ")])]):_vm._e()]}}],null,false,1581783802)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }