<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<!-- eslint-disable vue/no-unused-components -->
<template>
  <div>
    <b-row>
      <div class="limited-accounts">
        <h4 class="limited-accounts__heading infoText">
          Did you know that you can create limited accounts for your main account?
        </h4>
        <p class="limited-accounts__text">
          With a limited account, you can allow others to access specific sections of your account, such as tickets, news and product updates, and guides, without giving them access to critical information.
        </p>
        <p class="limited-accounts__text">
          Limited accounts have their own custom simple home page, where they can easily see the information they need without being overwhelmed. This feature is perfect for sharing your account with team members or partners who need access to certain information without being able to modify important data.
        </p>
        <p class="limited-accounts__text">
          Try creating a limited account today and see how it can improve your workflow!
        </p>
      </div>
    </b-row>
    <b-row>
      <b-col
        cols="9"
        class="mx-auto"
      >
        <form-wizard
          v-if="!edit"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          layout="vertical"
          finish-button-text="Submit"
          back-button-text="Previous"
          class="wizard-vertical mb-3"
          :start-index="0"
          @on-complete="formSubmitted"
        >

          <!-- access tab -->
          <tab-content
            ref="AccessTab"
            title="Create Account"
            icon="feather icon-user"
          >
            <validation-observer ref="rulesAccesTab">
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Access
                  </h5>
                  <small class="text-muted">
                    Enter The Access Details.
                  </small>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Email *"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="email"
                        type="email"
                        placeholder="john.doe@email.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Password *"
                    label-for="password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="password"
                      rules="required|min:6"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        type="password"
                        placeholder="Minimum of 6 characters"
                        vid="password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Confirm Password *"
                    label-for="confirmpassword"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="confirmpassword"
                      rules="required|confirmed:password"
                    >
                      <b-form-input
                        id="confirmpassword"
                        v-model="confirmpassword"
                        type="password"
                        placeholder="Re-type Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Additional Permissions :"
                  >
                    <b-form-checkbox-group v-model="selectedPermissions">
                      <b-form-checkbox
                        v-for="(permission, index) in additionalPermissions"
                        :key="index"
                        :value="permission"
                      >
                        {{ permission }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </b-col>
      <!-- EDITTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT -->
      <b-col
        cols="9"
        class="mx-auto"
      >
        <b-modal
          id="edit-modal"
          ok-title="Edit"
          size="lg"
          centered
          no-close-on-backdrop
          title="Edit Sub Account"
          container-class="modal-class"
          @ok="formEditted"
          @hidden="handleClose"
          @cancel="handleClose"
        >
          <form-wizard
            v-if="edit"
            color="#7367F0"
            :title="null"
            :subtitle="null"
            layout="vertical"
            finish-button-text="Edit"
            back-button-text="Previous"
            class="wizard-vertical"
            :start-index="0"
            @on-complete="formEditted"
          >

            <!-- access tab -->
            <tab-content
              ref="AccessTabEdit"
              title="Edit Account"
              icon="feather icon-edit"
            >
              <validation-observer ref="rulesAccesTab">
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Access
                    </h5>
                    <small class="text-muted">
                      Edit The Access Details.
                    </small>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Email *"
                      label-for="email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="email"
                          v-model="email"
                          type="email"
                          placeholder="john.doe@email.com"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Password *"
                      label-for="password"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="password"
                        rules="min:6"
                      >
                        <b-form-input
                          id="password"
                          v-model="password"
                          type="password"
                          placeholder="Minimum of 6 characters"
                          vid="password"
                        />
                        <small>Leave it blank if you don't wish to change it</small>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Confirm Password *"
                      label-for="confirmpassword"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="confirmpassword"
                        rules="min:6|confirmed:password"
                      >
                        <b-form-input
                          id="confirmpassword"
                          v-model="confirmpassword"
                          type="password"
                          placeholder="Re-type Password"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Additional Permissions :"
                    >
                      <b-form-checkbox-group v-model="selectedPermissions">
                        <b-form-checkbox
                          v-for="(permission, index) in additionalPermissions"
                          :key="index"
                          :value="permission"
                        >
                          {{ permission }}
                        </b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>
          </form-wizard>
        </b-modal>
      </b-col>
    </b-row>
    <div style="margin-top: 50px; margin-bottom: 100px;">
      <h3
        v-if="tableData.length > 0"
        style="margin-bottom: 25px;"
      >
        Your sub-accounts
      </h3>
      <vue-good-table
        v-if="tableData.length > 0"
        :columns="columns"
        :rows="tableData"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <div
            v-if="props.column.field ==='actions'"
            style="display:flex;gap:10px;"
          >
            <button
              v-b-modal.edit-modal
              class="btn btn-warning btn-sm"
              @click="editAccount(props.row.id)"
            >
              Edit
            </button>
            <button
              class="btn btn-danger btn-sm"
              @click="deleteAccount(props.row.id)"
            >
              Delete
            </button>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import axios from '@/libs/axios' // eslint-disable-line
import {
  BRow, BInputGroup,
  BCol, BInputGroupAppend,
  BFormGroup, BButton,
  BFormInput, BFormSelect,
  BFormCheckbox, BFormRadioGroup, BFormCheckboxGroup, BModal,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
import { VueGoodTable } from 'vue-good-table'
import NewPackage from './NewPackage.vue'

// eslint-disable-next-line no-unused-vars
const jsonpAdapter = require('axios-jsonp')

export default {
  components: {
    FormWizard,
    VueGoodTable,
    TabContent,
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BFormRadioGroup,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    'new-package': NewPackage,
  },
  data() {
    return {
      // tab 1
      edit: false,
      email: '',
      password: '',
      confirmpassword: '',
      additionalPermissions: ['Current Invoice', 'Payments History', 'Invoices History', 'Pro Forma History', 'Optional Features'],
      selectedPermissions: [],
      jsonPermissions: [],
      tableData: [],
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Permissions',
          field: 'permissions',
          formatFn: this.formatFn,
          sortable: false,
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
        },
      ],
    }
  },
  computed: {
  },
  mounted() {
    this.getLimitedAccounts()
  },
  methods: {
    handleClose() {
      this.$bvModal.hide('edit-modal')
      this.edit = false
      this.selectedPermissions = []
    },
    async deleteAccount(id) {
      // ask the user for confirmation
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this account?', {
        title: 'Delete Account',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async value => {
          if (value) {
            const payload = {
              id,
            }
            await axios.post('api/account/delete_limited_account', payload)
              .then(() => {
                this.$bvToast.toast('Account deleted successfully', {
                  title: 'Success',
                  variant: 'success',
                  solid: true,
                })
                this.getLimitedAccounts()
              })
              .catch(error => {
                this.$bvToast.toast('Error deleting account', {
                  title: 'Error',
                  variant: 'danger',
                  solid: true,
                })
                console.log(error)
              })
          }
        })
        .catch(err => {
          // An error occurred
          console.log(err)
        })
    },
    editAccount(id) {
      this.edit = true
      this.selectedPermissions = []
      const account = this.tableData.find(row => row.id === id)
      this.email = account.email
      // tick the checkboxes
      if (account.permissions.includes('CurrentInvoice')) {
        this.selectedPermissions.push('Current Invoice')
      }
      if (account.permissions.includes('PaymentsHistoryLimited')) {
        this.selectedPermissions.push('Payments History')
      }
      if (account.permissions.includes('InvoicesHistoryLimited')) {
        this.selectedPermissions.push('Invoices History')
      }
      if (account.permissions.includes('ProFormaHistoryLimited')) {
        this.selectedPermissions.push('Pro Forma History')
      }
      if (account.permissions.includes('OptionalFeaturesLimited')) {
        this.selectedPermissions.push('Optional Features')
      }
    },
    formatFn(value) {
      // eslint-disable-next-line no-param-reassign
      value = value.map(permission => {
        if (permission === 'CurrentInvoice') {
          return 'Current Invoice'
        }
        if (permission === 'PaymentsHistoryLimited') {
          return 'Payments History'
        }
        if (permission === 'InvoicesHistoryLimited') {
          return 'Invoices History'
        }
        if (permission === 'ProFormaHistoryLimited') {
          return 'Pro Forma History'
        }
        if (permission === 'OptionalFeaturesLimited') {
          return 'Optional Features'
        }
        if (permission === 'BillingLimited') {
          return null
        }
        return permission
      }).join(',  ')

      return value
    },
    async getLimitedAccounts() {
      const res = await axios.get('api/account/get_limited_accounts')
      const data = res.data.map(row => ({
        ...row,
        permissions: JSON.parse(row.permissions),
      }))
      this.tableData = data
    },
    async formEditted() {
      const permissionMap = {
        'Current Invoice': 'CurrentInvoice',
        'Payments History': 'PaymentsHistoryLimited',
        'Invoices History': 'InvoicesHistoryLimited',
        'Pro Forma History': 'ProFormaHistoryLimited',
        'Optional Features': 'OptionalFeaturesLimited',
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const permission in permissionMap) {
        if (this.selectedPermissions.includes(permission)) {
          this.jsonPermissions.push(permissionMap[permission])
        }
      }
      if (this.jsonPermissions.some(permission => permission.startsWith('CurrentInvoice') || permission.startsWith('ProFormaHistoryLimited') || permission.startsWith('PaymentsHistoryLimited') || permission.startsWith('InvoicesHistoryLimited'))) {
        this.jsonPermissions.push('BillingLimited')
      }
      // remove duplicates from array
      this.jsonPermissions = [...new Set(this.jsonPermissions)]
      const payload = {
        email: this.email,
        permissions: JSON.stringify(this.jsonPermissions),
        password: this.password,
        confirm_password: this.confirmpassword,
        id: this.tableData.find(row => row.email === this.email).id,
      }
      const res = await axios.patch('api/account/update_limited_account', payload)
      if (res.status === 200) {
        this.$bvToast.toast('Account edited successfully', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
        this.edit = false
        this.email = ''
        this.password = ''
        this.confirmpassword = ''
        this.selectedPermissions = []
        this.jsonPermissions = []
        this.getLimitedAccounts()
      } else {
        this.$bvToast.toast('Something went wrong', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
    async formSubmitted() {
      const permissionMap = {
        'Current Invoice': 'CurrentInvoice',
        'Payments History': 'PaymentsHistoryLimited',
        'Invoices History': 'InvoicesHistoryLimited',
        'Pro Forma History': 'ProFormaHistoryLimited',
        'Optional Features': 'OptionalFeaturesLimited',
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const permission in permissionMap) {
        if (this.selectedPermissions.includes(permission)) {
          this.jsonPermissions.push(permissionMap[permission])
        }
      }
      if (this.jsonPermissions.some(permission => permission.startsWith('CurrentInvoice') || permission.startsWith('ProFormaHistoryLimited') || permission.startsWith('PaymentsHistoryLimited') || permission.startsWith('InvoicesHistoryLimited'))) {
        this.jsonPermissions.push('BillingLimited')
      }
      // remove duplicates from array
      this.jsonPermissions = [...new Set(this.jsonPermissions)]
      const payload = {
        name: this.name,
        email: this.email,
        password: this.password,
        confirm_password: this.confirmpassword,
        permissions: JSON.stringify(this.jsonPermissions),
      }
      const res = await axios.post('/api/account/create_limited_account', payload)
      if (res.status === 200) {
        this.$bvToast.toast('Account Created Successfully', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
        // wait 1 seconds before reloading the page
        setTimeout(() => window.location.reload(), 1000)
      } else {
        this.$bvToast.toast('Account Creation Failed', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
.content-overlay {
  display: none;
}
.icon-user{
  color: white !important;
}
.modal-class{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#edit-modal .wizard-card-footer{
  display: none !important;
}
.wizard-icon-container{
  background-color:  #c23f8d !important;
}
.wizard-btn{
  background-color:  #c23f8d !important;
  color: white !important;
  border: none !important;
}
.stepTitle{
  color: #c23f8d !important;
}
.infoText{
  background-color: #c23f8d;
  position: relative;
  color: white;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(194, 63, 141, 0.5);
  opacity: 0.9;
}

.limited-accounts {
  margin-bottom: 50px;
  margin-top: 25px;
  padding: 25px 50px;
  background-color: #f7f7f7;
  border-radius: 5px;
}

.limited-accounts__heading {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.limited-accounts__text {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dark-layout .limited-accounts {
  background-color: unset;
}

.dark-layout .limited-accounts__text {
  background-color: #2a3247;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dark-layout .infoText{
  color: white;
}
</style>

<style scoped>
.b-form-group {
  margin-bottom: 20px;
}
.custom-control {
  margin-bottom: 25px;
}

</style>
