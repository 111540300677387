<template>
  <div>
    <!-- <b-form-group v-if="selectedPackage.uid==='7003'">
      <p class="newPackage">
        <b>Starter</b>
      </p>
      <p class="newPackage">
        5000 conversions + 5 million impressions (clicks) included
      </p>
    </b-form-group> -->
    <b-form-group v-if="selectedPackage.uid==='7004'">
      <p class="newPackage">
        <b>Professional</b>
      </p>
      <p class="newPackage">
        20.000 conversions + 15 million impressions (clicks) included
      </p>
    </b-form-group>
    <b-form-group v-if="selectedPackage.uid==='7005'">
      <p class="newPackage">
        <b>Agency</b>
      </p>
      <p class="newPackage">
        50.000 conversions + 25 million impressions (clicks) included
      </p>
    </b-form-group>
    <b-form-group v-if="selectedPackage.uid==='7006'">
      <p class="newPackage">
        <b>Enterprice</b>
      </p>
      <p class="newPackage">
        250.000 conversions + 50 million impressions (clicks) included
      </p>
    </b-form-group>
    <b-form-group v-if="selectedPackage.uid==='5003'">
      <p class="newPackage">
        <b>Agency</b>
      </p>
      <p class="newPackage">
        20,000 Signups and Deposits + 20 million impressions (clicks) included
      </p>
    </b-form-group>
    <b-form-group v-if="selectedPackage.uid==='6003'">
      <p class="newPackage">
        <b>Enterprice</b>
      </p>
      <p class="newPackage">
        50,000 Signups and Deposits + 50 million impressions (clicks) included
      </p>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
  },
  props: {
    selectedPackage: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
